.side-bar {
  background-color: #ffffff; /* Sidebar background color */
  color: #000000; /* Sidebar text color */
  border-right: 1px solid rgb(224, 224, 224);
  padding: 5px 5px 0px 0px;
  height: 100%;
}
.sidebar-item {
  padding-bottom: 30px;
  cursor: pointer;
  border-radius: 5px;
}
/* Add this to your CSS file */
.sidebar-item.selected {
  background-color: #967cf3;
  color: #fff;
  border-radius: 0px 25px 0px 25px;
}

.sidebar li:hover {
  background-color: #5435c2;
  color: #fff;
}

.sidebar h2 {
  margin-bottom: 20px;
  color: #007bff;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  cursor: pointer;
  margin-bottom: 2px; /* Adjust this value to decrease the space */
  padding: 8px;
  border-radius: 5px;
  transition: background-color 0.1s;
}

.Browse-wedding-vendors-container {
  margin: auto;
  /* height: 100vh; */
  width: 100%;
  /* overflow: scroll; */
  background-color: #fff;
}
.ReactModal__Overlay {
  z-index: 9999;
  background: #00000002 !important;
}
.ReactModal__Overlay--after-open {
  position: absolute;
}
.bwv-sidebar {
  width: 25%;
  background-color: #ffffff;
  overflow-y: auto;
  /* height: 100vh; */
  float: left;
  /* border-right: 1px solid black; */
}
.bwv-sidebar::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

/* .sidebar-for li:hover {
  background-color: #ebebeb;
  color: #000;
} */
.categoryList {
  /* padding-left: 60px; */
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
  /* max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden; */
}
.bwvoptions {
  display: flex;
  align-items: center;
  justify-content: start;
}
/* .categoryList .items-list {
  margin-left: 30px;
} */
.categoryList ul li {
  cursor: pointer;
}
.categoryList .selected {
  font-weight: bold;
  background-color: #967cf3;
  color: #fff;
}
.categoryList .selected:hover {
  font-weight: bold;
  background-color: #967cf3;
  color: #fff;
}
.categoryList .selected:has(.items-list ul li) {
  background-color: #ebebeb;
  color: #000;
}
.bwv-content {
  /* height: 100vh; */
  /* overflow: scroll; */
  /* padding: 10px; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

@media (max-width: 768px) {
  .bwv-sidebar {
    width: 100%; /* Make the sidebar take up full width on smaller screens */
    float: none; /* Remove float for better responsiveness */
    border-right: none; /* Remove the right border on smaller screens */
    padding: 10px; /* Adjust padding as needed */
  }
  .provider-name {
    font-size: 16px;
  }
  .show-results {
    font-size: 14px;
  }
  .wd_short {
    font-size: 12px;
  }
  .categoryList {
    padding-left: 20px; /* Adjust padding as needed */
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
/* card details */

.image {
  padding: 5px;
  border: 1px solid #e5e1e1;
  border-radius: 10px;
  margin-bottom: 5px;
}
.image img {
  height: 230px;
  /* border-radius: 50%; */
}
/* .card-details-details {
  background-color: rgb(196, 255, 204);
} */
.card-details-details .details {
  padding: 1px;
  border: 1px solid #e5e1e1;
  border-radius: 10px;
  margin-bottom: 5px;

  /* background-color: rgb(202, 216, 216); */
}
.sidebar-for {
  overflow-y: scroll;
  max-height: 120vh;
  overflow-x: hidden;
}
.sidebar-for::-webkit-scrollbar {
  width: 2px;
}
.sidebar-for::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.card-details-details .details .need-list {
  cursor: pointer;
  border-radius: 10px;
  color: #0043fc;
}
.card-details-details .details .need-list:hover {
  background-color: #c8c7c7;
}
.card-details-details .details .services {
  border: 1px solid #eae5e5;
  border-radius: 10px;
  margin-left: 0;
}
.option {
  background-color: #f3f3f3;
  border-radius: 25%;
  padding: 2px;
}
.ad-arrow-action {
  background-color: blue;
  color: #fff;
  border-radius: 100%;
  padding: 5px 5px 5px 8px;
  margin-right: 20px;
}

#mbbwv-filter-btn:hover {
  cursor: pointer;
}
#tglbwv-filter-close,
.mbbwv-filter-btn {
  display: none;
}
.categoryList .items-list li {
  font-size: 12px;
}
@media only screen and (max-width: 992px) {
  #tglbwv-filter-close,
  .mbbwv-filter-btn {
    display: block;
  }
  .bwv-sidebar {
    display: none;
    position: fixed;
    right: -100%;
    background-color: #fff;
    z-index: 1000;
    transition: all 2s;
    top: 0px;
    transition-duration: 2s;
    width: calc(100% - 15px);
    border-radius: 5px 0 0 0;
    border-right: none;
    padding: 1rem 2rem;

    overflow-y: auto;
  }
  .Bwvshort {
    flex-basis: 36%;
  }
  .bwvfilter-close {
    display: none !important;
  }
  .bwvfilter-show {
    display: block !important;
    right: 0px !important;
    /* height: auto !important; */
    width: 100% !important;
  }
}

/* Your CSS file or style block */
.small-button {
  padding: 2px 4px; /* Adjust the padding as needed */
  font-size: 10px; /* Adjust the font size as needed */
  margin: 1px;
  --bs-btn-hover-bg: #967cf3;
}
/* .small-button:hover {
  --bs-btn-bg: #8000ff;
} */
.ad-taret-link {
  font-size: 18px;
}
.categoryList .selected {
  border-left: 1px solid #dadadada;
  border-radius: 0px 0px 0px 25px;
  border-bottom: 1px solid #dadadada;
  padding: 15px 0px 10px 0px !important;
}
.items-list .selected-item {
  border-left: 1px solid #dadadada;
  border-radius: 0px 25px 0px 25px;
  border-bottom: 1px solid #dadadada;
}
.categoryList .selected:before,
.items-list .selected-item:before {
  content: url(../../../assets/images/minus.png);
  position: relative;
  left: -8px;
  top: 2px;
  background: #fff;
  border-radius: 100px;
}
.items-list li {
  border-bottom: 1px solid #ababab;
  padding: 10px 9px;
}
.items-list .selected-item {
  padding: 10px 0px 10px 0px;
}
.items-list li:last-child {
  border-bottom: 0px solid #ababab;
}
/* .categoryList ul li{
  margin:15px 0px;
} */
.items-list ul {
  margin-left: 20px;
}
.subCat-items-list ul {
  margin: 0px;
}
.subCat-items-list li {
  padding: 10px;
  margin: 0px;
}
.items-list li:hover {
  background: #967cf3;
  color: #fff;
  border-radius: 0px 25px 0px 25px;
}
.items-list .selected-item {
  background: #967cf3 !important;
  color: #fff;
}
/* .categoryList .selected{
  background:#ebebeb;
}  */
.selected-item:has(.subCat-items-list ul li),
.selected-item:has(.subCat-items-list ul li):hover {
  background: #ebebeb !important;
  color: #000;
}
.subCat-items-list .selected {
  background: #967cf3 !important;
  color: #fff;
  padding: 10px !important;
}
.subCat-items-list .selected:before {
  content: "";
  position: relative;
  left: -8px;
  top: 2px;
}
.wc-youtube iframe {
  height: 300px !important;
}
@media only screen and (max-width: 600px) {
  .bwv_cupon-title {
    font-size: 16px !important;
  }
  .bwv_cupon_desc {
    font-size: 12px !important;
  }
  .bwv_cupon-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px 0px 0px 10px;
  }
  .wc-youtube iframe {
    height: 150px !important;
  }
}
.ReactRibbons_leftLargeRibbon_gKkDpYQyfk5jpV4ST8hM {
  z-index: 1;
  top: -15px;
  height: 80px;
  width: 125px;
}
.ReactRibbons_leftLargeRibbonText_RBEVIcII4sHxyUIwiQhF {
  top: 7px;
  left: 5px;
}
.github-fork-ribbon {
  position: absolute !important;
  top: 33px !important;
  left: -50px !important;
  size: 14px !important;
}
.github-fork-ribbon-wrapper {
  z-index: 5 !important;
}

.createdPakaage {
  writing-mode: vertical-rl;
  text-align: center;
  font-size: 24px;
  line-height: 1.5;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #efe3af;
  padding: 10px;
  width: fit-content;
  transform: rotate(180deg);
}

@media only screen and (max-width: 1023px) {
  .createdPakaage {
    transform: rotate(0deg) !important;
    writing-mode: horizontal-tb !important;
  }
}
